import * as DOMPurify from 'dompurify';
import React, { useState, useEffect } from 'react';

const Enquirytracker = ({ content , et}) => {
    const [data, setData] = useState("Loading...");
	const [statusCode, setStatusCode] = useState();
    const getData = async ()  => {
		var loader = function () {
            fetch(et.et_api_url, {
                headers: {
                    Accept: 'text/html',
                }
            })
            .then(response => response.text())
            .then(scriptText => {
                const script = document.createElement('script'), tag = document.getElementsByTagName('script')[0];
                tag.parentNode.insertBefore(script, tag);
                script.text = scriptText;
                script.onerror= function() {
                    var etWidgetDivs = document.getElementsByClassName(et.et_w_class);
                    var testDivs = Array.prototype.filter.call(etWidgetDivs, function(etWidgetDiv) {
                        etWidgetDiv.innerHTML = '<p><span style="color:red">We are sorry for the inconvenience, but our forms are currently undergoing maintenance. Please check back again shortly.</span></p>';
                    });
                }
            })
        };
        if(et.et_api_url !="" && et.et_w_class!=""){
            //console.log("Hello")
            loader();
        }
	}

	useEffect(() => {
		getData();				
	}, []);

    const sanitizedContent = DOMPurify.sanitize(content, {
        ADD_TAGS: ['iframe','embed'],
        ADD_ATTR: ['src','target'],        
      });

    return (
        <>
            {et.et_w_position==0 &&
                <>
                    <div dangerouslySetInnerHTML={{__html: sanitizedContent}}></div>
                    <div className={et.et_w_class} data-widget-type={et.et_w_type} data-widget-id={et.et_w_id} data-form-id={et.et_w_form_id}></div>                    
                </>
            }
            {et.et_w_position==1 &&
                <>
                    <div className={et.et_w_class} data-widget-type={et.et_w_type} data-widget-id={et.et_w_id} data-form-id={et.et_w_form_id}></div>                    
                    <div dangerouslySetInnerHTML={{__html: sanitizedContent}}></div>
                </>
            }
        </>
    );
};

export default Enquirytracker;