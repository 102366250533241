import * as DOMPurify from 'dompurify';

const Content = ({ htmldata }) => {

    const sanitizedContent = DOMPurify.sanitize(htmldata, {
        ADD_TAGS: ['iframe','embed'],
        ADD_ATTR: ['src','target'],        
      });

    return (
        <div dangerouslySetInnerHTML={{__html: sanitizedContent}}></div>
    );
};

export default Content;